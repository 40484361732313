
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { getFileFromBase64 } from '@/utils';
import { QrReceiptClient } from '@/services/Services';
import profileQrCodeModal from '../modals/profileQrCodeModal.vue'

@Options({
    components: {
    }
})
export default class Rules extends Vue {

    videoStyle = {
        width: 'calc(100vw - 40px)',
        maxWidth: '440px',
    };

    canvasWidth = 400;
    canvasHeight = 300;
    identifier: string = "";
    
    mounted() {
        // this.startCamera();
    }

    updateCanvasSize() {
      const video: any = this.$refs.video;
      
      this.canvasWidth = video.clientWidth;
      this.canvasHeight = video.clientHeight;
    }

    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment'} });
        (this.$refs.video as any).srcObject = stream;
      } catch (error) {
        console.error("Errore nell'accesso alla fotocamera:", error);
      }
    }

    // takePhoto() {
    //   const video: any = this.$refs.video;
    //   const canvas: any = this.$refs.canvas;
    //   const context = canvas.getContext('2d');
      
    //   context.drawImage(video, 0, 0, canvas.width, canvas.height);

    //   var base64 = canvas.toDataURL('image/png');

    //   var file = getFileFromBase64(base64, (new Date()).toISOString().replaceAll(":", "_").replaceAll(".", "_") + ".png");

    //   QrReceiptClient.generateNewQrReceipt(file)
    //   .then( x => {
    //     this.$opModal.show(profileQrCodeModal, {
    //         identifier: x,
    //         widthCustom: this.canvasWidth
    //     })
    //   })
    // }

    generateQrCode(){
      QrReceiptClient.generateNewQrReceipt()
      .then( x => {
        this.$opModal.show(profileQrCodeModal, {
            identifier: x,
            widthCustom: this.canvasWidth
        })
      })
    }
}
