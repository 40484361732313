
import { StorageServices } from '@/services/StorageServices';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ProfileQrCodeModal extends Vue {

    @Prop() identifier: string;
    @Prop() widthCustom: number; 

    loaded: boolean = false;

    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 100);
    }

}
