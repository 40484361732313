import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "register" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "h1",
        localizedKey: "app_Genera<br> il Qr Code",
        text: "Genera<br> il Qr Code"
      }),
      _createVNode(_component_localized_text, {
        type: "p",
        localizedKey: "app_Per generare il Qr Code assicurati che il partecipante abbia uno scontrino valido.",
        text: "Per generare il Qr Code assicurati che il partecipante abbia uno scontrino valido.",
        class: "text mt-3"
      })
    ]),
    _createElementVNode("button", {
      class: "d-block btn btn-primary w-100 mx-auto mt-5",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.generateQrCode()))
    }, [
      _createVNode(_component_localized_text, {
        type: "h1",
        localizedKey: "app_Genera QR Code",
        text: "Genera QR Code"
      })
    ])
  ]))
}